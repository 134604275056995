<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('TelemarketingTasksRejectionReasons')"
    @cancel="onCancel('TelemarketingTasksRejectionReasons')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TelemarketingTasksRejectionReasonCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'TelemarketingTasksRejectionReasons',
      pageHeader: 'Создание причины отказа для телемаркетинга',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Причины отказа для телемаркетинга',
          route: { name: 'TelemarketingTasksRejectionReasons' },
        },
        {
          text: 'Создание причины отказа для телемаркетинга',
        },
      ],
      initialForm: {
        name: null,
      },
      form: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
